import axios from 'axios'

const BASE_URL = 'https://luigi-pizza.herokuapp.com/https://studio-api.preprod.nmkr.io/v2'

const options = {
  method: 'POST',
  url: BASE_URL,
  mode: 'cors',
  headers: {
    'accept': 'text/plain',
    'Authorization': `Bearer ${process.env.REACT_APP_API_KEY}`,
    'apikey': process.env.REACT_APP_API_KEY
  }
};

export const fetchFromAPI = async (url) => {
      const {data} = await axios.get(`${BASE_URL}/${url}`)

      return data
}

axios.request(options).then(function (response) {
	console.log(response.data);
}).catch(function (error) {
	console.error(error);
});