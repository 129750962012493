export default {
    "data" : {
        "corner" : [
            {
                type: "Normal",
                id: "1",
                isActive: false
            },
            {
                type: "Extra",
                id: "2",
                isActive: false
            },
            {
                type: "Double",
                id: "3",
                isActive: false
            }
        ],
        "base" : [
            {
                type: "Mozzarella",
                id: "4",
                isActive: false
            },
            {
                type: "Pomodoro",
                id: "5",
                isActive: false
            },
            {
                type: "Basic",
                id: "6",
                isActive: false
            },
            {
                type: "Double",
                id: "7",
                isActive: false
            }
        ],
        "toppings" : [
            {
                type:"Mushrooms",
                id:"8",
                isActive: false
            },
            {
                type:"Ham",
                id:"9",
                isActive: false
            },
            {
                type:"Peppers",
                id:"10",
                isActive: false
            },
            {
                type:"Onions",
                id:"11",
                isActive: false
            }
        ]
    }
}