import React from 'react'

const Canvas = ({corner, base, toppings}) => {
  let string = {corner: "", base: "", toppings: []}
  const activeCorner = corner.map(item => {
    return item.isActive ? string.corner = item.type : ""
  })
  const activeBase = base.map(item => {
    return item.isActive ? string.base = item.type : ""
  })
  const activeToppings = toppings.map(item => {
    return item.isActive ? item.type : null
  })
  React.useEffect(() => {
    string.toppings = []
    for(let ct=0; ct<activeToppings.length; ct++){
      if(toppings[ct].isActive){
        string.toppings.push(toppings[ct].type)
      }
    }
    console.log(string)
  }, [activeToppings])
  return (
    <div>
        Cornice: {activeCorner} <br/>
        Base pizza: {activeBase} <br/>
        Ingredienti: {activeToppings}
    </div>
  )
}

export default Canvas