import React from 'react'
import Section from './components/Section'
import Canvas from './components/Canvas'
import nftData from "./nftData"
import { fetchFromAPI } from './utils/fetchFromAPI'

const App = () => {
  const [sectionCorner, setSectionCorner] = React.useState(nftData.data.corner)
  const [sectionBase, setSectionBase] = React.useState(nftData.data.base)
  const [sectionToppings, setSectionToppings] = React.useState(nftData.data.toppings)

  const [cornerActive, setCornerActive] = React.useState(0)
  const [baseActive, setBaseActive] = React.useState(0)
  const [toppingsActive, setToppingsActive] = React.useState(0)

  const UID = "ffb6dcc5-fb30-4793-819f-dc65d04ca85961b3257e1dcf6b06809e1b7f0471073cc3ab79576ce3fbd07052eb18"
  let data =""
  
  React.useEffect(() => {
    data = fetchFromAPI(`ListProjects`)
  })

  React.useEffect(() => {
    if(!cornerActive){
      setSectionBase(nftData.data.base)
      setSectionToppings(nftData.data.toppings)
      setBaseActive(0)
      setToppingsActive(0)
    }
  }, [cornerActive])

  React.useEffect(() => {
    if(!baseActive){
      setSectionToppings(nftData.data.toppings)
      setToppingsActive(0)
    }
  }, [baseActive])
  
  return (
    <div>
      <div className="selection">
        <Section title="Corner" options={sectionCorner} maxActive={1} 
        prevActive={true} active={cornerActive} 
        setActive={setCornerActive} setSection={setSectionCorner} 
        />
        <Section title="Base" options={sectionBase} maxActive={1} 
        prevActive={cornerActive} active={baseActive} 
        setActive={setBaseActive} setSection={setSectionBase}
        />
        <Section title="Toppings" options={sectionToppings} maxActive={100} 
        active={toppingsActive} prevActive={baseActive} 
        setActive={setToppingsActive} setSection={setSectionToppings}
        />
      </div>
      <Canvas corner={sectionCorner} base={sectionBase} toppings={sectionToppings}/>
      Dati:{data}
    </div>
  )
}

export default App