import React from 'react'
import Button from './Button'

const Section = (props) => {
  
  const [option, setOption] = React.useState(props.options)
  const [active, setActive] = React.useState(props.active)

  React.useEffect(() => {
    setOption(props.options)
    setActive(props.active)
  }, [props.options, props.active])

  function activateIngredient(id, increase){
    if(props.prevActive){
      if((increase && props.maxActive > active) || (!increase && props.maxActive >= active)){
      props.setSection(oldSection => oldSection.map(item => {
        return item.id === id ?
        {...item, isActive: !item.isActive} : item
      }))
      return increase ? props.setActive(oldActive => oldActive+1) : props.setActive(oldActive => oldActive-1)
      }
      else if(increase && props.maxActive === active){
        props.setSection(oldSection => oldSection.map(item => {
          return item.id === id ?
          {...item, isActive: !item.isActive} : {...item, isActive: false}
        }))
      }
    }
  }
  
  const buttonOptions = option.map(item => {
    return <Button 
      key={item.id}
      item={item}
      isActive={item.isActive}
      activateIngredient={() => activateIngredient(item.id, item.isActive ? false : true) }
    />
  })
  return (
    <div>
      <h4 className="selection_title">{props.title}</h4>
      {buttonOptions}
    </div>
  )
}

export default Section